import React, { useState } from "react";
import "./style.css";
import { Button, Form, Input, Space, Table, message, Modal } from "antd";
import { Icon } from "@iconify/react";

const { confirm } = Modal;
const resetData = [
  {
    SeprationId: "175c8491-9f21-4047-aaef-0bc50bbdd35b",
    Leader: "Super Heavey",
    Followers: {
      SuperHeavy: "72",
      UpperHeavy: "96",
      LowerHeavy: "120",
      UpperMedium: "120",
      LowerMedium: "144",
      Light: "192",
    },
  },
  {
    SeprationId: "5aebd487-7457-4a14-a6bf-f08fe311db32",
    Leader: "Upper Heavey",
    Followers: {
      SuperHeavy: "72",
      UpperHeavy: "72",
      LowerHeavy: "96",
      UpperMedium: "96",
      LowerMedium: "120",
      Light: "168",
    },
  },
  {
    SeprationId: "5aebd487-7457-4a14-a6bf-f08fe311db33",
    Leader: "Lower Heavey",
    Followers: {
      SuperHeavy: "72",
      UpperHeavy: "72",
      LowerHeavy: "72",
      UpperMedium: "72",
      LowerMedium: "96",
      Light: "144",
    },
  },
  {
    SeprationId: "5aebd487-7457-4a14-a6bf-f08fe311db43",
    Leader: "Upper Medium",
    Followers: {
      SuperHeavy: "72",
      UpperHeavy: "72",
      LowerHeavy: "72",
      UpperMedium: "72",
      LowerMedium: "72",
      Light: "120",
    },
  },
  {
    SeprationId: "5aebd487-7457-4a14-a6bf-f08fe311dgh42",
    Leader: "Lower Medium",
    Followers: {
      SuperHeavy: "72",
      UpperHeavy: "72",
      LowerHeavy: "72",
      UpperMedium: "72",
      LowerMedium: "72",
      Light: "96",
    },
  },
  {
    SeprationId: "5aebd487-7457-4a14-a6bf-f08fe311db39",
    Leader: "Light",
    Followers: {
      SuperHeavy: "72",
      UpperHeavy: "72",
      LowerHeavy: "72",
      UpperMedium: "72",
      LowerMedium: "72",
      Light: "72",
    },
  },
];
export default function AircraftSeparation() {
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState(resetData);
  const [form] = Form.useForm();

  const column = [
    {
      title: "Leader",
      dataIndex: "Leader",
      key: "Leader",
      rowScope: "row",
      editable: false,
    },
    {
      title: "Followers",
      children: [
        {
          title: "Super Heavy (NM)",
          dataIndex: ["Followers", "SuperHeavy"],
          key: "SuperHeavy",
          editable: true,
        },
        {
          title: "Upper Heavy (NM)",
          dataIndex: ["Followers", "UpperHeavy"],
          key: "UpperHeavy",
          editable: true,
        },
        {
          title: "Lower Heavy (NM)",
          dataIndex: ["Followers", "LowerHeavy"],
          key: "LowerHeavy",
          editable: true,
        },
        {
          title: "Upper Medium (NM)",
          dataIndex: ["Followers", "UpperMedium"],
          key: "UpperMedium",
          editable: true,
        },
        {
          title: "Lower Medium (NM)",
          dataIndex: ["Followers", "LowerMedium"],
          key: "LowerMedium",
          editable: true,
        },
        {
          title: "Light (NM)",
          dataIndex: ["Followers", "Light"],
          key: "Light",
          editable: true,
        },
      ],
    },
    {
      title: "Action",
      key: "Action",
      editable: false,
      render: (_, data) => {
        return data.SeprationId === editingKey ? (
          <Space>
            <Button
              style={{ color: "green", borderColor: "green" }}
              icon={<Icon icon="dashicons:saved" />}
              onClick={() =>
                confirm({
                  title: "Do you Want to save these?",
                  // icon: <ExclamationCircleFilled />,
                  // content: "Some descriptions",
                  onOk() {
                    save(data.SeprationId);
                  },
                })
              }
            />
            <Button
              danger
              icon={<Icon icon="iconoir:cancel" />}
              onClick={() => setEditingKey("")}
            />
          </Space>
        ) : (
          <Space>
            <Button
              icon={<Icon icon="bitcoin-icons:edit-filled" />}
              onClick={() => edit(data)}
            />
            <Button
              icon={<Icon icon="material-symbols-light:delete" />}
              onClick={() =>
                confirm({
                  title: "Do you Want to delete these items?",
                  // icon: <ExclamationCircleFilled />,
                  // content: "Some descriptions",
                  onOk() {
                    handleDelete(data.SeprationId);
                  },
                })
              }
            />
          </Space>
        );
      },
    },
  ];

  const getOnCellProps = (child, record) => ({
    record,
    dataIndex: child.dataIndex,
    title: child.title,
    editing: record.SeprationId === editingKey,
  });

  const mergedColumns = column.map((col) => {
    if (col.children) {
      return {
        ...col,
        children: col.children?.map((child) =>
          !child.editable
            ? child
            : { ...child, onCell: (record) => getOnCellProps(child, record) }
        ),
      };
    } else if (!col.editable) {
      return col;
    }

    return { ...col, onCell: (record) => getOnCellProps(col, record) };
  });

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      ...record.Followers,
    });
    setEditingKey(record.SeprationId);
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const oldData = [...data];
      const newData = oldData.map((data) =>
        data.SeprationId === id ? { ...data, ...row } : data
      );
      if (newData.length) {
        setData(newData);
        setEditingKey("");
        message.success("data update successfully");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      message.error(`${errInfo.errorFields[0].errors[0]}`);
    }
  };

  const handleDelete = (id) => {
    const oldData = [...data];
    const newData = oldData.filter((row) => row.SeprationId !== id);
    setData(newData);
  };
  return (
    <div>
      <Form form={form} component={false}>
        <Table
          columns={mergedColumns}
          size="middle"
          bordered
          dataSource={data}
          rowKey={(data) => data.SeprationId}
          rowClassName={"editable-row"}
          components={{
            header: {
              cell: ({ children, ...props }) =>
                ["Followers", "Action", "Leader"].every(
                  (header) => children[1] !== header
                ) ? (
                  <th {...props} style={{ backgroundColor: "#E8E9EB" }}>
                    {children}
                  </th>
                ) : (
                  <th {...props}>{children}</th>
                ),
            },
            body: {
              cell: ({
                editing,
                dataIndex,
                title,
                record,
                index,
                children,
                ...restProps
              }) => {
                return restProps.scope ? (
                  <th {...restProps} style={{ backgroundColor: "#E8E9EB" }}>
                    {children}
                  </th>
                ) : (
                  <td {...restProps}>
                    {editing ? (
                      <Form.Item
                        name={dataIndex}
                        style={{
                          margin: 0,
                        }}
                        rules={[
                          {
                            required: false,
                            message: `Please Input ${title}!`,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      children
                    )}
                  </td>
                );
              },
            },
          }}
          pagination={{
            pageSize: 10,
            total: data.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            // showSizeChanger: true,
            // showQuickJumper: true,
          }}
        />
      </Form>
    </div>
  );
}
