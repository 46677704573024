import { Icon } from "@iconify/react";
import { Button, Form, Input, Modal, Space, Table, message } from "antd";
import moment from "moment";
import React, { useState } from "react";

const { confirm } = Modal;
const resetData = [
  {
    _id: "659bee94d78b9fc172274f39",
    TrackId: "46638b90-4fa0-4c2e-b6cd-c24cb5262a29",
    TrackName: "RTE94",
    UnderMaintenance: false,
    FromDateTime: "2022-01-03T14:30:00.000Z",
    ToDateTime: "2022-01-03T16:30:00.000Z",
    IsActive: "Temporary",
  },
  {
    _id: "659bee94d78b9fc172275f39",
    TrackId: "46638b90-4fa0-4c2e-b7cd-c24cb5262a29",
    TrackName: "RTE82",
    UnderMaintenance: false,
    FromDateTime: "2022-01-03T14:30:00.000Z",
    ToDateTime: "2022-01-03T16:30:00.000Z",
    IsActive: "Temporary",
  },
  {
    _id: "659bee94d78b9fc172276f39",
    TrackId: "46638b90-4fa0-4c2e-b8cd-c24cb5262a29",
    TrackName: "RTE83",
    UnderMaintenance: false,
    FromDateTime: "2022-02-13T14:30:00.000Z",
    ToDateTime: "2022-02-15T16:30:00.000Z",
    IsActive: "Temporary",
  },
  {
    _id: "659bee94d78b9fc172277f39",
    TrackId: "46638b90-4fa0-4c2e-b9cd-c24cb5262a29",
    TrackName: "RTE84",
    UnderMaintenance: true,
    FromDateTime: "2022-02-25T14:30:00.000Z",
    ToDateTime: "2022-03-02T16:30:00.000Z",
    IsActive: "Temporary",
  },
  {
    _id: "659bee94d78b9fc172278f39",
    TrackId: "46638b90-4fa0-4c2e-b0cd-c24cb5262a29",
    TrackName: "RTE85",
    UnderMaintenance: true,
    FromDateTime: "2022-02-27T14:30:00.000Z",
    ToDateTime: "2022-03-10T16:30:00.000Z",
    IsActive: "Temporary",
  },
];
export default function TrackRoutines() {
  const [data, setData] = useState(resetData);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();
  const column = [
    {
      title: "Track Name",
      dataIndex: "TrackName",
      key: "TrackName",
      editable: false,
    },
    {
      title: "Under Maintenance",
      dataIndex: "UnderMaintenance",
      key: "UnderMaintenance",
      editable: true,
      render: (value)=> value.toString()
    },
    {
      title: "From Date-Time",
      dataIndex: "FromDateTime",
      key: "FromDateTime",
      editable: true,
      render: (date)=> moment(date).format("YYYY-MM-DD")
    },
    {
      title: "To Date-Time",
      dataIndex: "ToDateTime",
      key: "ToDateTime",
      editable: true,
      render: (date)=> moment(date).format("YYYY-MM-DD")
    },
    {
      title: "Active",
      dataIndex: "IsActive",
      key: "IsActive",
      editable: true,
    },
    {
      title: "Action",
      key: "Action",
      editable: false,
      render: (_, data) => {
        return data.TrackId === editingKey ? (
          <Space>
            <Button
              style={{ color: "green", borderColor: "green" }}
              icon={<Icon icon="dashicons:saved" />}
              onClick={() =>
                confirm({
                  title: "Do you Want to save these?",
                  onOk() {
                    save(data.TrackId);
                  },
                })
              }
            />
            <Button
              danger
              icon={<Icon icon="iconoir:cancel" />}
              onClick={() => setEditingKey("")}
            />
          </Space>
        ) : (
          <Space>
            <Button
              icon={<Icon icon="bitcoin-icons:edit-filled" />}
              onClick={() => edit(data)}
            />
            <Button
              icon={<Icon icon="material-symbols-light:delete" />}
              onClick={() =>
                confirm({
                  title: "Do you Want to delete these items?",
                  onOk() {
                    handleDelete(data.TrackId);
                  },
                })
              }
            />
          </Space>
        );
      },
    },
  ];

  const getOnCellProps = (col, record) => ({
    record,
    dataIndex: col.dataIndex,
    title: col.title,
    editing: record.TrackId === editingKey,
  });

  const mergedColumns = column.map((col) => {
    if (!col.editable) {
      return col;
    }
    return { ...col, onCell: (record) => getOnCellProps(col, record) };
  });

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const oldData = [...data];
      const newData = oldData.map((data) =>
        data.TrackId === id ? { ...data, ...row } : data
      );
      if (newData.length) {
        setData(newData);
        setEditingKey("");
        message.success("data update successfully");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      message.error(`${errInfo.errorFields[0].errors[0]}`);
    }
  }
  const edit = (record) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record.TrackId);
  }
  const handleDelete = (id) => {
    const oldData = [...data];
    const newData = oldData.filter((row) => row.TrackId !== id);
    setData(newData);
  }
  return (
    <div>
      <Form form={form} component={false}>
        <Table
          columns={mergedColumns}
          dataSource={data}
          rowKey={(data) => data.TrackId}
          rowClassName={"editable-row"}
          components={{
            body: {
              cell: ({
                editing,
                dataIndex,
                title,
                record,
                index,
                children,
                ...restProps
              }) => {
                return restProps.scope ? (
                  <th {...restProps}>{children}</th>
                ) : (
                  <td {...restProps}>
                    {editing ? (
                      <Form.Item
                        name={dataIndex}
                        style={{
                          margin: 0,
                        }}
                        rules={[
                          {
                            required: false,
                            message: `Please Input ${title}!`,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : (
                      children
                    )}
                  </td>
                );
              },
            },
          }}
          pagination={{
            pageSize: 10,
            total: data.length,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            // showSizeChanger: true,
            // showQuickJumper: true,
          }}
        />
      </Form>
    </div>
  );
}
