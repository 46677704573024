import { io } from "socket.io-client";

export const connectSocket = () => {
  const socket = io(process.env.REACT_APP_BACKEND_URL, {
    transports: ["websocket", "polling"],
    secure: process.env.NODE_ENV === "production", // Ensures 'secure' is used only in production
    path: "/socket.io",  // Ensure the path matches your Nginx config
  });

  socket.on("connect", () => {
    console.log("Socket connected:", socket.id);
  });

  return socket;
};

export const closeConnection = (socket) => {
  socket.disconnect();
};
