import "./style.css";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GoogleMap,
  Marker,
  OverlayViewF,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import geoJson from "../../routesdata/route.geojson";
import flights from "../../models/flights";
import { Table } from "antd";
import moment from "moment-timezone";
import Loader from "../../plugin/loader/loader";
import { Icon } from "@iconify/react";
import routeNodes from "../../routesdata/routeNodes";
import { closeConnection, connectSocket } from "../../plugin/tools/socket";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 25.0732972,
  lng: 55.728425,
};

const airplaneSymbol = {
  path: "M60 70 c-21 -33 -33 -40 -62 -43 -51 -4 -51 -32 0 -36 29 -3 41 -10 62 -43 29 -43 51 -52 41 -16 -4 12 -9 30 -11 40 -4 13 1 17 25 17 16 0 33 -5 36 -10 13 -20 20 -9 20 30 0 39 -7 50 -20 30 -3 -5 -20 -10 -36 -10 -24 0 -29 4 -25 18 2 9 7 27 11 40 10 35 -12 26 -41 -17z",
  scale: 0.1,
  strokeColor: "#393",
  fillColor: "#393",
  fillOpacity: 1.0,
  strokeWeight: 1,
  rotation: 90,
  // anchor: { x: 50, y: 50 }
};

const nodeIcon = {
  path: "M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22",
  scale: 0.3,
  strokeColor: "#3bb0de",
  fillColor: "#66c1e5",
  fillOpacity: 1.0,
  strokeWeight: 1,
};

const params = {
  ...center,
  // lat: 25.248665,
  // lng: 55.352917,
  // lat: 24.973472000000072,
  // lng: 56.06838900000008,
  radius: 100,
  limit: 50,
  airport: "DXB",
};

function Atcm() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [selectedNode, setSelectedNode] = useState(null);
  const [tableOpen, setTableOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const [worker, setWorker] = useState(null);

  const onLoad = useCallback((map) => {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.data.loadGeoJson(geoJson);
    map.data.setStyle({ strokeColor: "red", zIndex: 10 });

    map?.data?.addListener("click", (event) => {
      setSelectedRoute(event);
    });
    setMap(map);
  }, []);

  const onUnmount = useCallback((map) => {
    map.data.setMap(null);
    setMap(null);
  }, []);
  const handleZoomChanged = () => {
    if (map) {
      setZoom(map.getZoom());
    }
  };
  const column = [
    {
      title: "SEQUENCE",
      dataIndex: "sequence",
      key: "sequence",
      fixed: "left",
      width: 90,
      sorter: (a, b) => a.sequence - b.sequence,
      defaultSortOrder: "ascend",
      // sortDirections: ["ascend"],
      // render: (_, __, index) => (page - 1) * 5 + index + 1,
    },
    {
      title: "ARRIVAL",
      children: [
        {
          title: "IMPED",
          dataIndex: ["arrivingFrom"],
          key: "Imped",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "IMPED" ? row?.identification?.callsign : "",
        },
        {
          title: "DB529",
          dataIndex: ["arrivingFrom"],
          key: "DB529",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "DB529" ? row?.identification?.callsign : "",
        },
        {
          title: "KEBOG",
          dataIndex: ["arrivingFrom"],
          key: "KEBOG",
          fixed: "left",
          width: 80,
          render: (arrivingFrom, row) =>
            arrivingFrom.node === "KEBOG" ? row?.identification?.callsign : "",
        },
      ],
    },
    {
      title: "AIRCRAFT TPYE",
      dataIndex: "aircraft",
      key: "aircraft",
      render: (aircraft) => aircraft?.model?.code,
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "SEPARATION (NM)",
      dataIndex: "separation",
      key: "separation",
      render: (separation) => separation / 24,
    },
    {
      title: "GAP (NM)",
      dataIndex: "aircraftGap",
      key: "aircraftGap",
    },
    {
      title: "ESTIMATE ARR. TIME (UTC)",
      dataIndex: "estimateArrivalTime",
      key: "estimateArrivalTime",
      render: (estimateArrivalTime) => {
        let arrTime = moment(estimateArrivalTime).utc().format("HH:mm:ss");
        return arrTime === "Invalid date" ? "--:--" : arrTime;
      },
      sorter: (a, b) =>
        a.estimateArrivalTime?.localeCompare(b.estimateArrivalTime),
      // sortDirections: ["ascend"],
      // defaultSortOrder: "ascend",
    },
    {
      title: "DELAY",
      dataIndex: "delay",
      key: "delay",
      render: (delay) => {
        const sign = delay < 0 ? "-" : "";
        const momentObject = moment.duration(Math.abs(delay));

        const hours = momentObject.hours();
        const minutes = momentObject.minutes();
        const seconds = momentObject.seconds();

        return `${sign}${hours}:${minutes}:${seconds}`;
      },
    },
    {
      title: "CALCULATED ARR. TIME (UTC)",
      dataIndex: "calculatedArrivalTime",
      key: "calculatedArrivalTime",
      render: (calculatedArrivalTime) => {
        let arrTime = moment(calculatedArrivalTime).utc().format("HH:mm:ss");
        return arrTime === "Invalid date" ? "--:--" : arrTime;
      },
    },
    {
      title: "REMAINING DISTANCE (NM)",
      dataIndex: "remainingDistance",
      key: "remainingDistance",
      render: (remainingDistance) => Number(remainingDistance).toFixed(2),
      sorter: (a, b) => a.remainingDistance - b.remainingDistance,
    },
    {
      title: "AIRCRAFT STATUS",
      dataIndex: "isOnRoute",
      key: "isOnRoute",
      render: (isOnRoute) => (isOnRoute ? "on route" : "off route"),
    },
    {
      title: "ROUTE STATUS",
      dataIndex: "route",
      key: "route",
      render: (route) => (route.length ? "available" : "on hold"),
    },
    {
      title: "BASE TURN",
      dataIndex: "baseTurn",
      key: "baseTurn",
    },
    {
      title: "CROSSING ALTITUDE",
      dataIndex: "crossingAltitude",
      key: "crossingAltitude",
    },
  ];

  const getModifiedFlightData = useMemo(() => {
    if (!data.length) return [];
    const modifiedFlightData = data?.filter(
      (flight) => flight?.estimateArrivalTime
    );
    return modifiedFlightData.map((flight, index) => ({
      sequence: index + 1,
      ...flight,
    }));
  }, [data]);

  useEffect(() => {
    if (selectedFlight) {
      const isFound = data?.find(
        (flight) =>
          flight?.identification?.id === selectedFlight?.identification?.id
      );

      setSelectedFlight(isFound ? isFound : null);
    }
  }, [data]);

  const getFlights = (params) => {
    setIsLoading(true);
    flights
      .getRadarFlights(params)
      .then((data) => {
        // setData(data);
        setPage(1);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteOnRouteFlights = () => {
    setIsLoading(true);
    flights
      .deleteOnRouteFlights()
      .then((resolve) => {
        console.log("delete success =>", resolve);
      })
      .catch((reject) => {
        console.log("delete error =>", reject);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const socketConnection = connectSocket();
    setSocket(socketConnection);

    return () => {
      if (socketConnection) {
        closeConnection(socketConnection);
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      const handleGetFlights = (data) => {
        setData(data);
      };
      socket.on("getFlights", handleGetFlights);

      return () => {
        socket.off("getFlights", handleGetFlights);
      };
    }
  }, [socket]);

  // Function to send a message to the worker thread
  const sendMessageToWorker = () => {
    if (worker) {
      worker.postMessage("hello");
    }
  };

  useEffect(() => {
    const newWorker = new Worker(
      new URL("../../plugin/workerThread/worker.js", import.meta.url)
    );
    setWorker(newWorker);

    return () => {
      newWorker?.terminate();
    };
  }, []);

  useEffect(() => {
    if (worker) {
      worker.onmessage = function (message) {
        console.log("Received message from worker:", message.data);
      };
    }
    sendMessageToWorker();
  }, [worker]);

  return (
    <>
      {isLoading && <Loader />}
      {/* Manzer Ansari */}
      <div className="main-container">
        <div
          id="map"
          className={`container-fluid pt-2 ${tableOpen ? "reduce-height" : ""}`}
        >
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onZoomChanged={handleZoomChanged}
              onClick={() => {
                setSelectedFlight(null);
                setSelectedNode(null);
                setSelectedRoute(null);
              }}
            >
              {selectedFlight && (
                <>
                  <OverlayViewF
                    position={selectedFlight.trail[0]}
                    mapPaneName="overlayMouseTarget"
                  >
                    <div className="custome-overlay">
                      <span className="fw-bold">
                        {selectedFlight?.identification?.callsign}
                      </span>
                    </div>
                  </OverlayViewF>
                  <Polyline
                    path={selectedFlight?.route}
                    options={{
                      geodesic: true,
                      strokeColor: "#39FF14",
                      strokeOpacity: 1.0,
                      strokeWeight: 3,
                      zIndex: 20,
                    }}
                  />
                </>
              )}
              {routeNodes.map((nodeData, index) => (
                <Marker
                  key={index}
                  position={nodeData}
                  icon={{
                    // url: pointer,
                    ...nodeIcon,
                    // scaledSize: new window.google.maps.Size(
                    //   selectedNode === index ? 12 : 6,
                    //   selectedNode === index ? 12 : 6
                    // ),
                    anchor: new window.google.maps.Point(12, 12),
                  }}
                  onClick={(e) => {
                    setSelectedNode({
                      latLng: e.latLng,
                      nodeData,
                    });
                  }}
                />
              ))}

              {getModifiedFlightData?.map((flightDetails, index) => {
                const { separationIndicator, trail, identification } =
                  flightDetails;
                let zoomLevel = zoom < 10 ? zoom + 0.5 * (10 - zoom) : zoom;
                let iconSize = 0.012 * zoomLevel;
                let scale = iconSize < 0.08 ? 0.08 : iconSize;
                if (trail[0]) {
                  return (
                    <Fragment key={identification?.id}>
                      <Marker
                        position={trail[0]}
                        icon={{
                          ...airplaneSymbol,
                          rotation: trail[0]?.hd + 90,
                          scale: scale,
                        }}
                        onClick={() => setSelectedFlight(flightDetails)}
                      />
                      {Object.keys(separationIndicator).length ? (
                        <OverlayViewF
                          position={separationIndicator}
                          mapPaneName="overlayMouseTarget"
                        >
                          <div
                            className="separation-indicator"
                            style={{
                              height: `${2.5 * zoom}px`,
                              width: `${2.5 * zoom}px`,
                            }}
                          >
                            <div className="dot"></div>
                          </div>
                        </OverlayViewF>
                      ) : null}
                    </Fragment>
                  );
                }
                return null;
              })}
              {selectedNode && (
                <OverlayViewF
                  position={selectedNode.latLng}
                  mapPaneName="overlayMouseTarget"
                >
                  <div className="custome-overlay">
                    <span className="fw-bold">
                      {selectedNode?.nodeData?.node}
                    </span>
                  </div>
                </OverlayViewF>
              )}
              {selectedRoute && (
                <OverlayViewF
                  position={selectedRoute?.latLng}
                  mapPaneName="overlayMouseTarget"
                >
                  <div className="custome-overlay">
                    <span className="fw-bold text-uppercase">
                      {selectedRoute?.feature?.getProperty("Name")}
                    </span>
                  </div>
                </OverlayViewF>
              )}
            </GoogleMap>
          ) : null}
        </div>
        <div className={`table-container ${tableOpen ? "table-open" : ""}`}>
          <div className="handle-container">
            <div
              className="table-handle"
              onClick={() => setTableOpen(!tableOpen)}
            >
              <span></span>
              <span></span>
            </div>
            <Icon
              icon="ic:round-refresh"
              fontSize={18}
              style={{ color: "#00838f", cursor: "pointer" }}
              onClick={() => {
                getFlights(params);
              }}
            />
            <Icon
              icon="material-symbols-light:delete"
              fontSize={18}
              style={{ color: "#00838f", cursor: "pointer" }}
              onClick={() => {
                deleteOnRouteFlights();
              }}
            />
          </div>
          <div
            className={`table-content scrollbar-none ${
              tableOpen ? "overflow-auto" : "overflow-hidden"
            }`}
          >
            <Table
              columns={column}
              showSorterTooltip={{ placement: "right" }}
              className="aircraft-table m-1"
              size="small"
              dataSource={getModifiedFlightData}
              rowKey={(data) => data?.identification?.id}
              scroll={{
                x: 1600,
                // y: 240
              }}
              sticky={{ offsetScroll: 0 }}
              pagination={{
                pageSize: 10,
                current: page,
                total: getModifiedFlightData?.length,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (current) => setPage(current),
              }}
              bordered
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Atcm;
