import React from "react";
import Navbar from "./components/navbar/Navbar";

function App() {
  return (
    <>
      <Navbar />
      {/* <div className="flex-grow-1 ">
        <Outlet />
      </div> */}
    </>
  );
}

export default App;
