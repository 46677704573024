import React from "react";
import "./loader.css";

export default function Loader() {
  return (
    <div className="loader-container">
      <div className="page-loader">
        {[...Array(20)].map((_, index) => (
          <span
            key={index}
            style={{
              "--i": index + 1,
            }}
          ></span>
        ))}
        <div className="plane"></div>
      </div>
    </div>
  );
}
